import { CustomerType } from "./CustomerType.js";
import { Locale, type LocalizedString } from "./Locale.js";

export enum PaymentType {
	GoPayPaymentCard = "GoPayPaymentCard",
	GoPayGPay = "GoPayGPay",
	GoPayApplePay = "GoPayApplePay",
	OnDelivery = "OnDelivery",
	OnInvoice = "OnInvoice",
}

export const paymentTypePerCustomerType = {
	[CustomerType.B2c]: [
		PaymentType.GoPayApplePay,
		PaymentType.GoPayGPay,
		PaymentType.GoPayPaymentCard,
		PaymentType.OnDelivery,
	] as const,
	[CustomerType.B2b]: [PaymentType.OnDelivery, PaymentType.OnInvoice] as const,
} satisfies Record<CustomerType, PaymentType[]>;

export type B2cPaymentType = (typeof paymentTypePerCustomerType)["B2c"][number];
export type B2bPaymentType = (typeof paymentTypePerCustomerType)["B2b"][number];

export const paymentTypeNameMap: Record<PaymentType, LocalizedString> = {
	[PaymentType.GoPayPaymentCard]: { [Locale.cs]: "Kartou" },
	[PaymentType.GoPayGPay]: { [Locale.cs]: "Google Pay" },
	[PaymentType.GoPayApplePay]: { [Locale.cs]: "Apple Pay" },
	[PaymentType.OnDelivery]: { [Locale.cs]: "Dobírkou" },
	[PaymentType.OnInvoice]: { [Locale.cs]: "Na fakturu" },
};

export const paymentTypeLongNameMap: Record<PaymentType, LocalizedString> = {
	[PaymentType.GoPayPaymentCard]: { [Locale.cs]: "Platební kartou" },
	[PaymentType.GoPayGPay]: { [Locale.cs]: "Google Pay" },
	[PaymentType.GoPayApplePay]: { [Locale.cs]: "Apple Pay" },
	[PaymentType.OnDelivery]: { [Locale.cs]: "Platba na místě hotově nebo kartou" },
	[PaymentType.OnInvoice]: { [Locale.cs]: "Úhrada na fakturu převodem" },
};
