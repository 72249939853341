import { OrderPlacedBy } from "./OrderPlacedBy.js";

export type CustomerType = Extract<OrderPlacedBy, OrderPlacedBy.B2c | OrderPlacedBy.B2b>;

export const CustomerType = {
	[OrderPlacedBy.B2c]: OrderPlacedBy.B2c,
	[OrderPlacedBy.B2b]: OrderPlacedBy.B2b,
} as const;

export const customerTypeNameMap: Record<CustomerType, string> = {
	[CustomerType.B2c]: "B2C zákazník",
	[CustomerType.B2b]: "B2B zákazník",
};
